import React, {useEffect, useState} from 'react';
import Login from './components/Login/Login';
import Members from './containers/Members/Members';
import {fetchPermissions} from './request/fetchPermissions';

/*
* todo: Виникло декілька питаннь: (БутенкоЕВ)
    1. Чи можна додати колонку/ список 1, 2, 3...при перегляді В групи пастора, а не лише номера із загального списку?
    2. Чи можна додати разове посилання членам для актуалізації/доповнення даних?
    3. Як це експортувати для друку з різною деталізацією?
    4. Чи є синхронізація дат народження, дат хрещення із гугл календарем пастора?
    5. DONE(30/09/2023) add column ministry "задіян у служінні" to members
*
*
*
* */

function App() {
    const unsetUser = (setUser = () => {}) => {
        setUser(undefined);
        localStorage.setItem('reUser', '"undefined"');
        localStorage.setItem('fullMembersList', '[]');
        localStorage.setItem('rowData', '[]');
        localStorage.setItem('isPastorSelected', 'false');

        return undefined;
    }
    const [user, setUser] = useState( JSON.parse(localStorage.getItem('reUser')) === 'undefined' ? unsetUser() : JSON.parse(localStorage.getItem('reUser')));
    const [message, setMessage] = useState( "");

    const handleUser = user => {
        if(user && user.pending) return setMessage(user.pending);
        if(!user || Date.now() >= user.expire) return unsetUser(setUser);

        setUser(user);
        setMessage("");
        localStorage.setItem('reUser', JSON.stringify(user));
    }
    const expire = () => !user || Date.now() >= parseInt(user.expire)

    useEffect(() => {
        if(expire()) unsetUser(setUser);
        fetchPermissions(user)
            .then(result => handleUser(result))
    }, []);

    return (
            <div>
                {expire()
                    ? <Login handleUser={handleUser} message={message} />
                    : <Members user={user} handleUser={handleUser} />
                }
            </div>
    );
}

export default App;
