export function fetchPermissions(user) {
    if(!user) return Promise.resolve(undefined);

    const api = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST_PROD : process.env.REACT_APP_API_HOST_DEV;

    return fetch(api, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            'Authorization': user.token,
            'Get-Staff-Permissions': user.id,
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
    })
        .then(data => {
            if(data.ok) return data.json();
            if(!data.ok) return undefined;
        })
        .catch(() => {return undefined})
}
