import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
import './Members.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import 'ag-grid-enterprise';
import {AG_GRID_LOCALE_EN} from '../../locale.en.js';
import {fetchMemberList} from '../../request/fetchMemberListGoogleSheets';
import {fetchStaffList} from '../../request/fetchStaffList';
import {updateMember} from '../../request/updateMember';
import {updateStaff} from '../../request/updateStaff';
import FullWidthCellRenderer from './fullWidthCellRenderer.jsx';
import Filter from '../../components/Filter/Filter';
import Search from '../../components/Search/Search';
import Tools from '../../components/Tools/Tools';

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
};
function getDetailedInfoBlockWidth() {
    return window.screen.width <= 485 ? (window.screen.height / 2) + 50 : window.screen.height / 2;
}

function Members(props) {
    const gridRef = useRef();
    const [rowData, setRowData] = useState(JSON.parse(localStorage.getItem('rowData')));
    const [fullMembersList, setFullMembersList] = useState(JSON.parse(localStorage.getItem('fullMembersList')));
    const [staffList, setStaffList] = useState([]);
    const [isPastorSelected, setIsPastorSelected] = useState(JSON.parse(localStorage.getItem('isPastorSelected')) || false);
    const [columnDefs, setColumnDefs] = useState([
                {field: 'i', headerName: '#', minWidth: 65, width: 65},
                {field: 'shortMemberInfo', headerName: 'ПІБ', suppressSizeToFit: true},
                {field: 'pastor', headerName: 'Пастор', suppressSizeToFit: true}
            ]);
    const localeText = useMemo(() => {
        return AG_GRID_LOCALE_EN;
    }, []);
    const defaultColDef = useMemo( ()=> ({
        sortable: true
    }));
    const setFullWidthRowId = () => {
        gridRef.current.api.fullWidthRowId = 0;
        gridRef.current.api.resetRowHeights();
        gridRef.current.api.redrawRows(gridRef);
    }
    const showDetailedInfo = (id) => {
        gridRef.current.api.onChangeMember = {isChanged: false, id: undefined};
        gridRef.current.api.saveUpdatedMemberData = saveUpdatedMemberData;
        gridRef.current.api.setFullWidthRowId = setFullWidthRowId;
        gridRef.current.api.fullWidthRowId = id;
        gridRef.current.api.resetRowHeights();
        gridRef.current.api.redrawRows(gridRef);
    }
    const handleMembers = result => {
        if(!result) return;

        let fullMembersList = JSON.parse(result);
        let rowData = fullMembersList
            .filter(member => {
                if(props.user.admin === "1") return true;

                return (member.excommunicated + member.moved + member.died).length === 0;
            })
            .map((member, i) => {
                member.shortMemberInfo = member.lastName + " " + member.firstName + " " + member.middleName;
                member.i = ++i;

                return member;
            });

        setFullMembersList(fullMembersList);
        setRowData(rowData);

        localStorage.setItem('fullMembersList', JSON.stringify(fullMembersList));
        localStorage.setItem('rowData', JSON.stringify(rowData));
        gridRef.current.api.onChangeMember = {isChanged: false, id: undefined};
    };
    const saveUpdatedMemberData = useCallback(() => {
        if(!gridRef.current.api.onChangeMember?.isChanged) return;

        updateMember(props.user, props.handleUser, gridRef.current.api.updatedMember)
            .then(result => handleMembers(result))

        gridRef.current.api.onChangeMember = {isChanged: false, id: undefined};
    }, [rowData]);
    const cellClickedListener = useCallback( event => {
        saveUpdatedMemberData();

        let key = Object.keys(event.data).find(key => event.data[key] === event.value);

        if(key === 'pastor') {
            let selectedRowData;
            if(isPastorSelected) {
                selectedRowData = fullMembersList.filter(member => {
                    if(props.user.admin === "1") return true;

                    return (member.excommunicated + member.moved + member.died).length === 0;
                }).filter(row => row).map((row, i) => {
                    row.i = ++i;
                    return row;
                });
            } else {
                selectedRowData = fullMembersList.filter(member => {
                    if(props.user.admin === "1") return true;

                    return (member.excommunicated + member.moved + member.died).length === 0;
                }).filter(row => row.pastor === event.value).map((row, i) => {
                    row.i = ++i;
                    return row;
                });
            }

            setRowData(selectedRowData);
            localStorage.setItem('rowData', JSON.stringify(selectedRowData));
            localStorage.setItem('isPastorSelected', JSON.stringify(!isPastorSelected));
            setIsPastorSelected(!isPastorSelected);
        } else {
            showDetailedInfo(event.data.id);
        }
    }, [fullMembersList, isPastorSelected, setIsPastorSelected, rowData]);
    const autoSizeAll = useCallback((skipHeader) => {
        gridRef.current.api.sizeColumnsToFit();

    }, []);
    const isFullWidth = (member, rowId) => {
        return [rowId].indexOf(member.id) >= 0;
    };
    const isFullWidthRow = useCallback((params) => {
        return isFullWidth(params.rowNode.data, params.api.fullWidthRowId);
    }, []);
    const fullWidthCellRenderer = useMemo(() => {
        return FullWidthCellRenderer;
    }, []);
    const getRowHeight = useCallback((params) => {
        // console.log('getRowHeight', params.data.pastor, params.user.pastor, params.user.admin);
        // console.log(params.data.pastor, props.user.pastor, props.user.admin);
        if (isFullWidth(params.data, params.api.fullWidthRowId)) {

            // console.log('params.data', Object.keys(params.data).length);
            // console.log(' window.innerHeigh',  window.innerHeight);

            return iOS()
                ? params.data.pastor === props.user.pastor || parseInt(props.user.admin) === 1 ? getDetailedInfoBlockWidth() : 230
                : params.data.pastor === props.user.pastor || parseInt(props.user.admin) === 1 ? getDetailedInfoBlockWidth() : 200;
            // return window.innerWidth < window.innerHeight ? 270 : 100;
        }
    }, []);

    useEffect(() => {
        let mounted = true;
        fetchMemberList(props.user, props.handleUser)
            .then(result => handleMembers(result));

        fetchStaffList(props.user, props.handleUser)
            .then(result => setStaffList(result));

        return () => mounted = false;
    }, [props.user.admin]);

    return (
        <div className={'members-container'}>
            <div className={'header-container'}>
                <div className={'header-title'}>Re:Church</div>
                <div className={'buttons-container'}>
                    <Search fullMembersList={fullMembersList} setRowData={setRowData} />
                    <Filter gridRef={gridRef} />
                    <Tools
                        staffList={staffList}
                        setStaffList={setStaffList}
                        user={props.user}
                        handleUser={props.handleUser}
                    />
                </div>
            </div>
            <div className="ag-theme-alpine" style={{
                    width: "100%",
                    height: window.screen.height,
            }}>
                <AgGridReact
                    ref={gridRef}
                    localeText={localeText}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    onCellClicked={cellClickedListener}
                    onFirstDataRendered={autoSizeAll}
                    isFullWidthRow={isFullWidthRow}
                    fullWidthCellRenderer={fullWidthCellRenderer}
                    getRowHeight={getRowHeight}
                    saveUpdatedMemberData={saveUpdatedMemberData}
                />
            </div>
        </div>
    );
}

export default Members;
