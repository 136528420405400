export function fetchStaffList(user, handleUser) {
    if(parseInt(user.admin) !== 1) return Promise.resolve([]);

    const api = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST_PROD : process.env.REACT_APP_API_HOST_DEV;

    return fetch(api, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            'Authorization': user.token,
            'Fetch-Staff-List': "true",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
    })
        .then(data => data.json())
        .catch(() => handleUser(undefined))
}
