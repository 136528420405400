import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import reportWebVitals from './reportWebVitals';

const clientId = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_GOOGLE_PROD_ID : process.env.REACT_APP_GOOGLE_DEV_ID;
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GoogleOAuthProvider clientId={clientId}>
        <App />
    </GoogleOAuthProvider>
);

reportWebVitals();
