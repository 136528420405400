export function updateMember(user, handleUser, member) {
    const api = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST_PROD : process.env.REACT_APP_API_HOST_DEV;
    return fetch(api, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            'Authorization': user.token,
            'Update-member': member.id,
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: JSON.stringify(member), // body data type must match "Content-Type" header
    })
        .then(data => {
            if(!data.ok) return handleUser(undefined);
            if(data.ok) return data.json();
        })
        .catch(() => handleUser(undefined))
}
