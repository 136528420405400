import React, { useState } from 'react';
import './Search.css';

const SearchButton = (props) => {
    return (
        <svg
            fill="#000000"
            height="25px"
            width="25px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 518.462 518.462"
            onClick={() => props.setIsOpen(!props.isOpen)}
        >
            <g>
                <g>
                    <path d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6
			s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2
			S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7
			S381.9,104.65,381.9,203.25z"/>
                </g>
            </g>
        </svg>
    )
};
const SearchOptions = (props) => {
    const [searchMemberValue, setSearchMemberValue] = useState('');
    const onSearchMember = ((event) => {
        setSearchMemberValue(event.target.value);
        let nextRowData = props.fullMembersList.slice(0)
            .filter(row =>
                event.target.value.length === 0
                ||
                row.lastName.includes(event.target.value)
                ||
                row.firstName.includes(event.target.value)
                ||
                row.pastor.includes(event.target.value)
                ||
                row.tel1.includes(event.target.value)
                ||
                row.tel2.includes(event.target.value)
                ||
                row.tel3.includes(event.target.value)
                ||
                row.ministry && row.ministry.includes(event.target.value)
                ||
                row.leader && row.leader.includes(event.target.value)
            );

        props.setRowData(nextRowData);
        localStorage.setItem('rowData', JSON.stringify(nextRowData));
    });

    return (
        <div>{
            props.isOpen
                ? <div className={'search-options-container'} style={{width: window.innerWidth * 0.9}}>
                    <div className={'search-options-list'}>
                        <div style={{marginLeft: 10, marginRight: 30}}>Пошук:</div>
                        <input
                            className={'search-input'}
                            placeholder="пошук"
                            autoFocus
                            value={searchMemberValue}
                            onChange={onSearchMember}
                        />
                    </div>
                </div>
                : <div />
        }
        </div>
    )
};

export default (params) => {
    const [isOpen, setIsOpen] = useState(false);
    const [update, setUpdate] = useState(false);

    return (
        <div className={'search-container'} >
            <SearchButton isOpen={isOpen} setIsOpen={setIsOpen} />
            <SearchOptions isOpen={isOpen} fullMembersList={params.fullMembersList} setRowData={params.setRowData}/>
        </div>
    );
};
