export function fetchMemberList(user, handleUser) {
    const url = "https://script.google.com/macros/s/AKfycby-jaEQNJIj6R4IwTbp1tP9kkoeUoiRDVAL1V37G_x5PI-eynoeLe7NjQvY5_6x1pJRFg/exec";
    const options = {
        method: "GET",
        mode: 'cors',
        redirect: "follow", // manual, *follow, error
        credentials: "same-origin", // include, *same-origin, omit
    };
    return fetch(url, options)
        .then((response) => {
            if(!response.ok) return handleUser(undefined);
            if(response.ok) return response.body;
        })
        .then((rb) => {
            const reader = rb.getReader();

            return new ReadableStream({
                start(controller) {
                    function push() {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            push();
                        });
                    }

                    push();
                },
            });
        })
        .then((stream) =>
            new Response(stream, { headers: { "Content-Type": "text/html" } }).text(),
        )
        .then((result) => {
            return result;
        });
}
